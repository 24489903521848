@tailwind base;
@tailwind components;
@tailwind utilities;

.rdg-cell,
.rdg-checkbox {
  border-color: rgb(207, 207, 207) !important;
  border-width: 1px;
}

.rdg-cell[role='columnheader'] {
  background-color: rgb(233, 233, 233);
}

.toolbar {
  height: auto !important;
}

.ez-svg {
  overflow: visible;
}

.ez-axis-tick-text {
  font-size: 0.65rem;
}

.ez-tooltip {
  font-size: 0.75rem;
}

.ez-tooltip-attribute--name {
  max-width: 256px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.selected-geo-feature {
  filter: drop-shadow(4px 4px 5px black);
  stroke-width: 2px;
  stroke: black;
}

.character-style-popup button.popup-item {
  padding: 4px !important;
}

.editor-container,
.editor-shell {
  height: 100% !important;
  padding-bottom: 1rem !important;
}

.ContentEditable__root {
  height: 100% !important;
  border: 1px solid #e5e7eb;
  padding-right: 1rem;
}
